import Vue from "vue";
import store from "../store";
import vuetify from "../plugins/vuetify";
import router from "../router";
import config from "../config";
import { getS3URL } from "../helpers/misc";

export default {
  snackbarStart() {
    store.state.snackbar.show = false;
    store.state.snackbar.text = store.state.snackbar.submittingText;
    store.state.snackbar.color = "primary";
    store.state.snackbar.timeout = 0;
    store.state.snackbar.show = true;
  },

  snackbarSuccess() {
    store.state.snackbar.show = false;
    store.state.snackbar.text = store.state.snackbar.submittedText;
    store.state.snackbar.timeout = 2500;
    store.state.snackbar.color = "success";
    store.state.snackbar.show = true;
    setTimeout(() => (store.state.snackbar.show = false), 2500);
  },

  snackbarError() {
    store.state.snackbar.show = false;
    store.state.snackbar.text = store.state.snackbar.errorText;
    store.state.snackbar.timeout = 2500;
    store.state.snackbar.color = "error";
    store.state.snackbar.show = true;
    setTimeout(() => (store.state.snackbar.show = false), 2500);
  },

  snackbarAccessDenied() {
    store.state.snackbar.show = false;
    store.state.snackbar.text = "Make the payment for full access";
    store.state.snackbar.timeout = 2500;
    store.state.snackbar.color = "error";
    store.state.snackbar.show = true;
    setTimeout(() => (store.state.snackbar.show = false), 5000);
  },

  setStoreAppearance() {
    const color =
      store.state.activeStore.dashboardColor ||
      store.state.activeStore.appColor ||
      (store.state.activeStore.vendorOwner
        ? store.state.activeStore.vendorOwner.dashboardColor ||
          store.state.activeStore.vendorOwner.appColor
        : false);
    if (color) {
      const primaryColor = color.substring(0, 7);
      vuetify.framework.theme.themes.light.primary = primaryColor;
      document.documentElement.style.setProperty("--primary", primaryColor);
    }

    this.setFavicon();
  },

  setDefaultAppearance() {
    const primaryColor = "#007bff";
    vuetify.framework.theme.themes.light.primary = primaryColor;
    document.documentElement.style.setProperty("--primary", primaryColor);
  },

  setFavicon() {
    let storea = store.state.activeStore;
    if (!storea) return;

    if (storea.isVendor) storea = store.state.activeStore.vendorOwner;
    if (!storea || !storea.appLogo) return;

    const url = getS3URL(storea._id, storea.appLogo);
    var link = document.querySelector("link[rel~='icon']");
    if (!link) {
      link = document.createElement("link");
      link.rel = "icon";
      document.getElementsByTagName("head")[0].appendChild(link);
    }
    link.href = url;
  },

  redirectToHome() {
    router.push("/businesses");
  },

  isMultivendorUI(array) {
    if (!array || !array.length) return false;
    const as = store.state.activeStore;
    if (!as) return false;
    return (
      as.isMultivendor &&
      !as.isMultivendorDashboardOnly &&
      array.includes(as.multivendorUIType)
    );
  },

  createNewStore(store, noProgress = true) {
    return Vue.axios.post(`store`, store, { noProgress: noProgress });
  },

  getStores(_id, noProgress = true) {
    return Vue.axios.get(`store/list/${_id}`, { noProgress: noProgress });
  },

  getAllActiveStores(noProgress = true) {
    return Vue.axios.get(`store/list/all/active`, { noProgress: noProgress });
  },

  getStore(_id, noProgress = true) {
    return Vue.axios.get(`store/${_id}`, { noProgress: noProgress });
  },

  getDashboardFromDomain(hostname, noProgress = true) {
    return Vue.axios.post(
      `store/dashboard/verify`,
      { hostname: hostname },
      { noProgress: noProgress }
    );
  },

  getUnreadCounts(_id, noProgress = true) {
    return Vue.axios.get(
      `store/${_id}/${
        store.state.activeStore.isVendor ? "v-counts" : "counts"
      }/unread`,
      { noProgress: noProgress }
    );
  },

  getStoreCharts(_id, noProgress = true) {
    return Vue.axios.get(
      `store/${
        store.state.activeStore.isVendor ? "v-charts" : "charts"
      }/${_id}`,
      { noProgress: noProgress }
    );
  },

  getStoreNumbers(_id, noProgress = true) {
    return Vue.axios.get(
      `store/${_id}/${
        store.state.activeStore.isVendor ? "v-numbers" : "numbers"
      }`,
      { noProgress: noProgress }
    );
  },

  getCardData(_id, type, date, noProgress = true) {
    return Vue.axios.get(
      `store/${
        store.state.activeStore.isVendor ? "v-sales" : "sales"
      }/${_id}/${type}/${date}`,
      { noProgress: noProgress }
    );
  },

  getStoreEnums(_id, noProgress = true) {
    return Vue.axios.get(`store/enums/${_id}`, { noProgress: noProgress });
  },

  getDeliveryModes(noProgress = true) {
    return Vue.axios.get(`delivery-modes`, { noProgress: noProgress });
  },

  getStoreSettings(_id, noProgress = true) {
    return Vue.axios.get(`store/${_id}/settings`, { noProgress: noProgress });
  },

  processPayment(_id, object, noProgress = true) {
    return Vue.axios.post(`store/${_id}/process-payment`, object, {
      noProgress: noProgress,
    });
  },

  processCredits(_id, object, noProgress = true) {
    return Vue.axios.post(`store/${_id}/process-sms-credits`, object, {
      noProgress: noProgress,
    });
  },

  updateStore(_id, object, noProgress = true) {
    return Vue.axios.put(`store/${_id}`, object, { noProgress: noProgress });
  },

  updateStoreWebSettings(_id, type, object, noProgress = true) {
    return Vue.axios.put(`store/domain/${type}/settings/${_id}`, object, {
      noProgress: noProgress,
    });
  },

  //coupons
  getCoupons(_id, noProgress = true) {
    return Vue.axios.get(`coupon/list/${_id}`, { noProgress: noProgress });
  },

  createCoupon(storeId, coupon, noProgress = true) {
    return Vue.axios.post(`coupon/${storeId}`, coupon, {
      noProgress: noProgress,
    });
  },

  editCoupon(_id, coupon, noProgress = true) {
    return Vue.axios.put(`coupon/${_id}`, coupon, { noProgress: noProgress });
  },

  deleteCoupon(_id, noProgress = true) {
    return Vue.axios.delete(`coupon/${_id}`, { noProgress: noProgress });
  },

  getCouponEnums(_id, noProgress = true) {
    return Vue.axios.get(`coupon/enums/${_id}`, { noProgress: noProgress });
  },

  validateCouponCode(_id, body, noProgress = true) {
    return Vue.axios.post(`coupon/validate/${_id}`, body, {
      noProgress: noProgress,
    });
  },

  //banners
  getBanners(_id, noProgress = true) {
    return Vue.axios.get(`banner/list/${_id}/all`, { noProgress: noProgress });
  },

  getMainBanners(_id, noProgress = true) {
    return Vue.axios.get(`banner/list/${_id}/main/all`, {
      noProgress: noProgress,
    });
  },

  getMiniBanners(_id, noProgress = true) {
    return Vue.axios.get(`banner/list/${_id}/mini/all`, {
      noProgress: noProgress,
    });
  },

  createBanner(_id, banner, noProgress = true) {
    return Vue.axios.post(`banner/${_id}`, banner, { noProgress: noProgress });
  },

  editBanner(_id, banner, noProgress = true) {
    return Vue.axios.put(`banner/${_id}`, banner, { noProgress: noProgress });
  },

  deleteBanner(_id, noProgress = true) {
    return Vue.axios.delete(`banner/${_id}`, { noProgress: noProgress });
  },

  //popups
  getPopups(_id, noProgress = true) {
    return Vue.axios.get(`popup/list/${_id}/all`, { noProgress: noProgress });
  },

  createPopup(_id, popup, noProgress = true) {
    return Vue.axios.post(`popup/${_id}`, popup, { noProgress: noProgress });
  },

  editPopup(_id, popup, noProgress = true) {
    return Vue.axios.put(`popup/${_id}`, popup, { noProgress: noProgress });
  },

  deletePopup(_id, noProgress = true) {
    return Vue.axios.delete(`popup/${_id}`, { noProgress: noProgress });
  },

  getIntegrations(_id, noProgress = true) {
    return Vue.axios.get(`integration/list/all/${_id}`, {
      noProgress: noProgress,
    });
  },

  getIntegration(_id, type, noProgress = true) {
    return Vue.axios.get(`integration/list/${type}/${_id}`, {
      noProgress: noProgress,
    });
  },

  createIntegration(_id, object, noProgress = true) {
    return Vue.axios.post(`integration/${_id}`, object, {
      noProgress: noProgress,
    });
  },

  editIntegration(_id, object, noProgress = true) {
    return Vue.axios.put(`integration/${_id}`, object, {
      noProgress: noProgress,
    });
  },

  getDeals(_id, noProgress = true) {
    return Vue.axios.get(`deal/list/${_id}`, { noProgress: noProgress });
  },

  createDeal(_id, deal, noProgress = true) {
    return Vue.axios.post(`deal/${_id}`, deal, { noProgress: noProgress });
  },

  editDeal(_id, deal, noProgress = true) {
    return Vue.axios.put(`deal/${_id}`, deal, { noProgress: noProgress });
  },

  deleteDeal(_id, noProgress = true) {
    return Vue.axios.delete(`deal/${_id}`, { noProgress: noProgress });
  },

  getDealEnums(_id, noProgress = true) {
    return Vue.axios.get(`deal/enums/${_id}`, { noProgress: noProgress });
  },

  searchProducts(_id, keyword, noProgress) {
    return Vue.axios.get(`search/p/${_id}?keyword=${keyword}`, {
      noProgress: noProgress,
    });
  },

  searchMVProducts(_id, keyword, noProgress) {
    return Vue.axios.get(`search/mv-p/${_id}?keyword=${keyword}`, {
      noProgress: noProgress,
    });
  },

  getCommonEnums() {
    return Vue.axios.get(`common-enums`, { noProgress: false });
  },

  getPricing() {
    return Vue.axios.get(`pricing/plans`, { noProgress: false });
  },

  getPricingEnums() {
    return Vue.axios.get(`pricing-enums`, { noProgress: false });
  },

  getCommonData(props) {
    return Vue.axios.get(`common-data?props=${props}`, { noProgress: false });
  },

  processCSV(_id, url, noProgress = true) {
    return Vue.axios.post(
      `csv/${_id}`,
      { url: url },
      { noProgress: noProgress }
    );
  },

  processProducts(_id, products, noProgress = true) {
    return Vue.axios.post(`product/import/${_id}`, products, {
      noProgress: noProgress,
    });
  },

  heavyImportProducts(_id, products, noProgress = true) {
    return Vue.axios.post(`product/heavy-import/${_id}`, products, {
      noProgress: noProgress,
    });
  },

  isDuplicateProduct(_id, product, noProgress = true) {
    return Vue.axios.post(`product/verify/duplicacy/${_id}`, product, {
      noProgress: noProgress,
    });
  },

  //releases
  getReleases(_id, noProgress = true) {
    return Vue.axios.get(`release/list/${_id}`, { noProgress: noProgress });
  },

  createRelease(_id, release, noProgress = true) {
    return Vue.axios.post(`release/${_id}`, release, {
      noProgress: noProgress,
    });
  },

  editRelease(_id, release, noProgress = true) {
    return Vue.axios.put(`release/${_id}`, release, { noProgress: noProgress });
  },

  deleteRelease(_id, noProgress = true) {
    return Vue.axios.delete(`release/${_id}`, { noProgress: noProgress });
  },

  getReleaseEnums(_id, noProgress = true) {
    return Vue.axios.get(`release/enums/${_id}`, { noProgress: noProgress });
  },

  //employees
  getEmployees(_id, noProgress = true) {
    return Vue.axios.get(`employee/list/${_id}`, { noProgress: noProgress });
  },

  addEmployee(_id, employee, noProgress = true) {
    return Vue.axios.post(`employee/${_id}`, employee, {
      noProgress: noProgress,
    });
  },

  editEmployee(_id, employee, noProgress = true) {
    return Vue.axios.put(`employee/${_id}`, employee, {
      noProgress: noProgress,
    });
  },

  deleteEmployee(_id, noProgress = true) {
    return Vue.axios.delete(`employee/${_id}`, { noProgress: noProgress });
  },

  getEmployeeEnums(_id, noProgress = true) {
    return Vue.axios.get(`employee/enums/${_id}`, { noProgress: noProgress });
  },

  getEmployeesByRole(_id, role, noProgress = true) {
    return Vue.axios.get(`employee/list/${role}/${_id}`, {
      noProgress: noProgress,
    });
  },

  editDefaultDeliveryman(_id, body, noProgress = true) {
    return Vue.axios.put(`employee/default-dm/${_id}`, body, {
      noProgress: noProgress,
    });
  },

  //states
  getStates(noProgress = true) {
    return Vue.axios.get(`state/list/all`, { noProgress: noProgress });
  },

  getState(stateId, noProgress = true) {
    return Vue.axios.get(`state/${stateId}`, { noProgress: noProgress });
  },

  getStatesByCountry(_id, noProgress = true) {
    return Vue.axios.get(`state/list/${_id}`, { noProgress: noProgress });
  },

  addState(_id, state, noProgress = true) {
    return Vue.axios.post(`state/${_id}`, state, { noProgress: noProgress });
  },

  editState(_id, state, noProgress = true) {
    return Vue.axios.put(`state/${_id}`, state, { noProgress: noProgress });
  },

  deleteState(_id, noProgress = true) {
    return Vue.axios.delete(`state/${_id}`, { noProgress: noProgress });
  },

  //cities
  getCities(noProgress = true) {
    return Vue.axios.get(`city/list/all`, { noProgress: noProgress });
  },

  getCity(cityId, noProgress = true) {
    return Vue.axios.get(`city/${cityId}`, { noProgress: noProgress });
  },

  getCitiesByState(_id, noProgress = true) {
    return Vue.axios.get(`city/list/${_id}`, { noProgress: noProgress });
  },

  addCity(_id, city, noProgress = true) {
    return Vue.axios.post(`city/${_id}`, city, { noProgress: noProgress });
  },

  editCity(_id, city, noProgress = true) {
    return Vue.axios.put(`city/${_id}`, city, { noProgress: noProgress });
  },

  deleteCity(_id, noProgress = true) {
    return Vue.axios.delete(`city/${_id}`, { noProgress: noProgress });
  },

  //superadmin stuff
  getAllStores(noProgress = true) {
    return Vue.axios.get(`store/list/all`, { noProgress: noProgress });
  },

  getPaginatedStores(
    pageNumber,
    pageSize,
    keyword,
    bodyObject,
    noProgress = true
  ) {
    let endpoint = `store/list/${pageNumber}/${pageSize}`;
    if (keyword) endpoint += `?keyword=${keyword}`;
    return Vue.axios.post(endpoint, bodyObject, { noProgress: noProgress });
  },

  //OOU
  // getAllStoresPaginated(pageNumber, pageSize, keyword, noProgress = true) {
  //   let endpoint = `store/list/all/${pageNumber}/${pageSize}`;
  //   if (keyword) endpoint += `?keyword=${keyword}`;
  //   return Vue.axios.get(endpoint, {noProgress: noProgress});
  // },

  deleteStore(_id, noProgress = false) {
    return Vue.axios.delete(`store/power/${_id}`, { noProgress: noProgress });
  },

  //superadmin stuff - countries
  getCountries(noProgress = true) {
    return Vue.axios.get(`country/list/all`, { noProgress: noProgress });
  },

  getActiveCountries(noProgress = true) {
    return Vue.axios.get(`country/list/active`, { noProgress: noProgress });
  },

  getCountry(_id, noProgress = true) {
    return Vue.axios.get(`country/${_id}`, { noProgress: noProgress });
  },

  addCountry(country, noProgress = true) {
    return Vue.axios.post(`country`, country, { noProgress: noProgress });
  },

  editCountry(_id, country, noProgress = true) {
    return Vue.axios.put(`country/${_id}`, country, { noProgress: noProgress });
  },

  deleteCountry(_id, noProgress = true) {
    return Vue.axios.delete(`country/${_id}`, { noProgress: noProgress });
  },

  //accessibility
  doIHaveAdminAccess($store) {
    const croles = $store.state.userInfo.roles;
    return (
      $store.state.activeStore.amIOwner ||
      ($store.state.activeStore.isVendor &&
        $store.state.userInfo._id ===
          $store.state.activeStore.vendorOwner.owner) ||
      croles.includes("superadmin") ||
      croles.includes("growcify-employee") ||
      $store.state.activeStore.role === "admin"
    );
  },

  doIHaveTestingAccess($store) {
    const croles = $store.state.userInfo.roles;
    return (
      $store.state.activeStore.amIOwner ||
      croles.includes("superadmin") ||
      croles.includes("growcify-employee") ||
      $store.state.activeStore.role === "tester"
    );
  },

  amIAllowed($store, module) {
    const ui = $store.state.userInfo;
    const as = $store.state.activeStore;

    return (
      ui.roles.includes("superadmin") ||
      ui.roles.includes("growcify-employee") ||
      (as.whoAmI === "owner" && !as.isVendor) ||
      (as.whoAmI === "parent-owner" && as.isVendor) ||
      (as.whoAmI === "owner" &&
        as.isVendor &&
        (!as.vendorModules ||
          !as.vendorModules.length ||
          as.vendorModules.includes(module))) ||
      (as.whoAmI === "employee" && as.modules.includes(module)) ||
      (as.whoAmI === "parent-employee" && as.modules.includes("Vendors"))
    );
  },

  doIHaveAccess($store, access) {
    let roles = [];
    if (access === "create") roles = ["admin", "creator"];
    else if (access == "edit") roles = ["admin", "creator", "editor"];
    else if (access === "delete") roles = ["admin"];
    else if (access === "actions") roles = ["admin", "creator", "editor"];

    const ui = $store.state.userInfo;
    const as = $store.state.activeStore;

    return (
      ui.roles.includes("superadmin") ||
      ui.roles.includes("growcify-employee") ||
      (as.whoAmI === "owner" && !as.isVendor) ||
      (as.whoAmI === "parent-owner" && as.isVendor) ||
      (as.whoAmI === "owner" && as.isVendor) ||
      (as.whoAmI === "employee" &&
        roles.includes($store.state.activeStore.role)) ||
      (as.whoAmI === "parent-employee" &&
        roles.includes($store.state.activeStore.role))
    );
  },

  amISuperadmin($store) {
    return $store.state.userInfo.roles.includes("superadmin");
  },

  amIGrowcifyEmployee($store) {
    return $store.state.userInfo.roles.includes("growcify-employee");
  },

  covidCategory($store) {
    return [
      "COVID-19: Food & Beverages",
      "COVID-19: Essentials & Grocery",
      "COVID-19: Medical & Pharmacy",
      "COVID-19: Other (Good Cause)",
    ].includes($store.state.activeStore.category);
  },

  //reports
  getReports(_id, noProgress = true) {
    return Vue.axios.get(`report/list/${_id}`, { noProgress: noProgress });
  },

  generateReport(_id, report, noProgress = true) {
    return Vue.axios.post(`report/${_id}`, report, { noProgress: noProgress });
  },

  editReport(_id, report, noProgress = true) {
    return Vue.axios.put(`report/${_id}`, report, { noProgress: noProgress });
  },

  deleteReport(_id, noProgress = true) {
    return Vue.axios.delete(`report/${_id}`, { noProgress: noProgress });
  },

  getReport(_id, noProgress = true) {
    return Vue.axios.get(`report/${_id}`, { noProgress: noProgress });
  },

  //locations
  getLocations(_id, noProgress = true) {
    return Vue.axios.get(`location/list/${_id}`, { noProgress: noProgress });
  },

  addLocation(_id, location, noProgress = true) {
    return Vue.axios.post(`location/${_id}`, location, {
      noProgress: noProgress,
    });
  },

  editLocation(_id, location, noProgress = true) {
    return Vue.axios.put(`location/${_id}`, location, {
      noProgress: noProgress,
    });
  },

  deleteLocation(_id, noProgress = true) {
    return Vue.axios.delete(`location/${_id}`, { noProgress: noProgress });
  },

  getLocationEnums(_id, noProgress) {
    return Vue.axios.get(`location/${_id}/enums`, { noProgress: noProgress });
  },

  //delivery slots
  getDeliverySlots(_id, noProgress = true) {
    return Vue.axios.get(`slot/list/${_id}`, { noProgress: noProgress });
  },

  addDeliverySlot(_id, deliverySlot, noProgress = true) {
    return Vue.axios.post(`slot/${_id}`, deliverySlot, {
      noProgress: noProgress,
    });
  },

  editDeliverySlot(_id, deliverySlot, noProgress = true) {
    return Vue.axios.put(`slot/${_id}`, deliverySlot, {
      noProgress: noProgress,
    });
  },

  deleteDeliverySlot(_id, noProgress = true) {
    return Vue.axios.delete(`slot/${_id}`, { noProgress: noProgress });
  },

  //delivery options
  getDeliveryOptions(_id, noProgress = true) {
    return Vue.axios.get(`delivery-option/list/${_id}`, {
      noProgress: noProgress,
    });
  },

  addDeliveryOption(_id, deliveryOption, noProgress = true) {
    return Vue.axios.post(`delivery-option/${_id}`, deliveryOption, {
      noProgress: noProgress,
    });
  },

  editDeliveryOption(_id, deliveryOption, noProgress = true) {
    return Vue.axios.put(`delivery-option/${_id}`, deliveryOption, {
      noProgress: noProgress,
    });
  },

  deleteDeliveryOption(_id, noProgress = true) {
    return Vue.axios.delete(`delivery-option/${_id}`, {
      noProgress: noProgress,
    });
  },

  //outlets
  getOutlets(_id, noProgress = true) {
    return Vue.axios.get(`outlet/list/${_id}`, { noProgress: noProgress });
  },

  addOutlet(_id, outlet, noProgress = true) {
    return Vue.axios.post(`outlet/${_id}`, outlet, { noProgress: noProgress });
  },

  editOutlet(_id, outlet, noProgress = true) {
    return Vue.axios.put(`outlet/${_id}`, outlet, { noProgress: noProgress });
  },

  deleteOutlet(_id, noProgress = true) {
    return Vue.axios.delete(`outlet/${_id}`, { noProgress: noProgress });
  },

  //bank-account
  getBankAccount(_id, noProgress = true) {
    return Vue.axios.get(`bank-account/${_id}`, { noProgress: noProgress });
  },

  getBankAccountEnums(_id, noProgress = true) {
    return Vue.axios.get(`bank-account/enums/${_id}`, {
      noProgress: noProgress,
    });
  },

  addBankAccount(_id, object, noProgress = true) {
    return Vue.axios.post(`bank-account/${_id}`, object, {
      noProgress: noProgress,
    });
  },

  editBankAccount(_id, object, noProgress = true) {
    return Vue.axios.put(`bank-account/${_id}`, object, {
      noProgress: noProgress,
    });
  },

  //memberships
  getMemberships(_id, noProgress = true) {
    return Vue.axios.get(`membership/list/${_id}`, { noProgress: noProgress });
  },

  getActiveMemberships(_id, noProgress = true) {
    return Vue.axios.get(`membership/list/${_id}/active`, {
      noProgress: noProgress,
    });
  },

  addMembership(_id, membership, noProgress = true) {
    return Vue.axios.post(`membership/${_id}`, membership, {
      noProgress: noProgress,
    });
  },

  editMembership(_id, membership, noProgress = true) {
    return Vue.axios.put(`membership/${_id}`, membership, {
      noProgress: noProgress,
    });
  },

  deleteMembership(_id, noProgress = true) {
    return Vue.axios.delete(`membership/${_id}`, { noProgress: noProgress });
  },

  getRetnalStatus(_id, noProgress = false) {
    return Vue.axios.get(`store/status/${_id}`, { noProgress: noProgress });
  },

  //notifications
  getNotifications(_id, noProgress = true) {
    return Vue.axios.get(`notification/list/${_id}`, {
      noProgress: noProgress,
    });
  },

  createNotification(_id, object, noProgress = true) {
    return Vue.axios.post(`notification/${_id}`, object, {
      noProgress: noProgress,
    });
  },

  editNotification(_id, object, noProgress = true) {
    return Vue.axios.put(`notification/${_id}`, object, {
      noProgress: noProgress,
    });
  },

  deleteNotification(_id, noProgress = true) {
    return Vue.axios.delete(`notification/${_id}`, { noProgress: noProgress });
  },

  processNotification(_id, noProgress = true) {
    return Vue.axios.post(`notification/process/${_id}`, {
      noProgress: noProgress,
    });
  },

  //header-requests
  getHRs(noProgress = true) {
    return Vue.axios.get(`header/list/all`, { noProgress: noProgress });
  },

  getHR(_id, noProgress = true) {
    return Vue.axios.get(`header/${_id}`, { noProgress: noProgress });
  },

  createHR(_id, object, noProgress = true) {
    return Vue.axios.post(`header/${_id}`, object, { noProgress: noProgress });
  },

  editHR(_id, object, noProgress = true) {
    return Vue.axios.put(`header/${_id}`, object, { noProgress: noProgress });
  },

  deleteHR(_id, noProgress = true) {
    return Vue.axios.delete(`header/${_id}`, { noProgress: noProgress });
  },

  processHeader(_id, object, noProgress = true) {
    return Vue.axios.post(`header/process/${_id}`, object, {
      noProgress: noProgress,
    });
  },

  getHREnums(_id = "", noProgress = false) {
    return Vue.axios.get(`header/enums${_id ? "/" + _id : ""}`, {
      noProgress: noProgress,
    });
  },

  //sms templates
  getTemplates(noProgress = true) {
    return Vue.axios.get(`sms-template/list`, { noProgress: noProgress });
  },

  createTemplate(object, noProgress = true) {
    return Vue.axios.post(`sms-template`, object, { noProgress: noProgress });
  },

  editTemplate(_id, object, noProgress = true) {
    return Vue.axios.put(`sms-template/${_id}`, object, {
      noProgress: noProgress,
    });
  },

  deleteTemplate(_id, noProgress = true) {
    return Vue.axios.delete(`sms-template/${_id}`, { noProgress: noProgress });
  },

  getTemplateEnums(_id = "", noProgress = false) {
    return Vue.axios.get(`sms-template/enums${_id ? "/" + _id : ""}`, {
      noProgress: noProgress,
    });
  },

  getSMSHistory(pageNumber, pageSize, keyword, noProgress = true) {
    let endpoint = `sms/list/history/${pageNumber}/${pageSize}`;
    if (keyword) endpoint += `?keyword=${keyword}`;

    return Vue.axios.get(endpoint, { noProgress: noProgress });
  },

  getSMSStoreHistory(_id, pageNumber, pageSize, keyword, noProgress = true) {
    let endpoint = `sms/history/${_id}/${pageNumber}/${pageSize}`;
    if (keyword) endpoint += `?keyword=${keyword}`;

    return Vue.axios.get(endpoint, { noProgress: noProgress });
  },

  processMapping(body, noProgress = true) {
    return Vue.axios.post(`sms-template/process-mapping`, body, {
      noProgress: noProgress,
    });
  },

  //shipment wallet
  getShipmentWallet(storeId, noProgress = true) {
    return Vue.axios.get(`wallet/shipment/${storeId}`, {
      noProgress: noProgress,
    });
  },

  addShipmentWalletBalance(storeId, balanceObj, noProgress = true) {
    return Vue.axios.post(`wallet/shipment/add/${storeId}`, balanceObj, {
      noProgress: noProgress,
    });
  },

  getShipmentWalletHistory(storeId, shipmentWalletId, noProgress = true) {
    return Vue.axios.get(
      `wallet/shipment/history/${storeId}/${shipmentWalletId}`,
      { noProgress: noProgress }
    );
  },

  //pickup addresse
  addPickupAddress(storeId, address, noProgress = true) {
    return Vue.axios.post(`shipment/pickup/address/${storeId}`, address, {
      noProgress: noProgress,
    });
  },

  editPickupAddress(addressId, address, noProgress = true) {
    return Vue.axios.put(`shipment/pickup/address/${addressId}`, address, {
      noProgress: noProgress,
    });
  },

  deletePickupAddress(addressId, noProgress = true) {
    return Vue.axios.delete(`shipment/pickup/address/${addressId}`, {
      noProgress: noProgress,
    });
  },

  getPickupAddresses(storeId, noProgress = false) {
    return Vue.axios.get(`shipment/pickup/address/list/${storeId}`, {
      noProgress: noProgress,
    });
  },

  //policies
  addPolicy(storeId, policy, noProgress = true) {
    return Vue.axios.post(`policy/${storeId}`, policy, {
      noProgress: noProgress,
    });
  },

  editPolicy(policyId, policy, noProgress = true) {
    return Vue.axios.put(`policy/${policyId}`, policy, {
      noProgress: noProgress,
    });
  },

  deletePolicy(policyId, noProgress = true) {
    return Vue.axios.delete(`policy/${policyId}`, { noProgress: noProgress });
  },

  getPolicies(storeId, noProgress = false) {
    return Vue.axios.get(`policy/list/${storeId}/all`, {
      noProgress: noProgress,
    });
  },

  //about us
  addAbout(storeId, about, noProgress = true) {
    return Vue.axios.post(`about/${storeId}`, about, {
      noProgress: noProgress,
    });
  },

  editAbout(aboutId, about, noProgress = true) {
    return Vue.axios.put(`about/${aboutId}`, about, { noProgress: noProgress });
  },

  getAbout(storeId, noProgress = false) {
    return Vue.axios.get(`about/list/${storeId}`, { noProgress: noProgress });
  },

  //contact us
  addContact(storeId, contact, noProgress = true) {
    return Vue.axios.post(`contact/${storeId}`, contact, {
      noProgress: noProgress,
    });
  },

  editContact(contactId, contact, noProgress = true) {
    return Vue.axios.put(`contact/${contactId}`, contact, {
      noProgress: noProgress,
    });
  },

  getContact(storeId, noProgress = false) {
    return Vue.axios.get(`contact/list/${storeId}`, { noProgress: noProgress });
  },

  //vendors
  getVendors(_id, noProgress = true) {
    return Vue.axios.get(`vendor/list/${_id}`, { noProgress: noProgress });
  },

  getVendor(_id, noProgress = true) {
    return Vue.axios.get(`vendor/${_id}`, {
      noProgress: noProgress,
    });
  },

  addVendor(_id, vendor, noProgress = true) {
    return Vue.axios.post(`vendor/${_id}`, vendor, { noProgress: noProgress });
  },

  editVendor(_id, vendor, noProgress = true) {
    return Vue.axios.put(`vendor/${_id}`, vendor, { noProgress: noProgress });
  },

  deleteVendor(_id, noProgress = true) {
    return Vue.axios.delete(`vendor/${_id}`, { noProgress: noProgress });
  },

  getVendorEnums(_id, noProgress = true) {
    return Vue.axios.get(`vendor/enums/${_id}`, { noProgress: noProgress });
  },

  getVendorsSummary(_id, object, noProgress = true) {
    return Vue.axios.post(`vendor/summary/${_id}`, object, {
      noProgress: noProgress,
    });
  },

  getVendorReviews(storeId, vendorId, noProgress = true) {
    return Vue.axios.get(`vendor/list/all/reviews/${storeId}/${vendorId}`, {
      noProgress: noProgress,
    });
  },

  deleteVendorReview(reviewId, noProgress = true) {
    return Vue.axios.delete(`vendor/review/${reviewId}`, {
      noProgress: noProgress,
    });
  },

  //requests
  getOwnerRequests(_id, noProgress = true) {
    return Vue.axios.get(`request/list/owner/${_id}`, {
      noProgress: noProgress,
    });
  },

  getRequests(_id, noProgress = true) {
    return Vue.axios.get(`request/list/${_id}`, { noProgress: noProgress });
  },

  createRequest(_id, ownerStoreId, request, noProgress = true) {
    return Vue.axios.post(`request/${_id}/${ownerStoreId}`, request, {
      noProgress: noProgress,
    });
  },

  editRequest(_id, request, noProgress = true) {
    return Vue.axios.put(`request/${_id}`, request, { noProgress: noProgress });
  },

  deleteRequest(_id, noProgress = true) {
    return Vue.axios.delete(`request/${_id}`, { noProgress: noProgress });
  },

  getRequestsEnums(_id, noProgress = true) {
    return Vue.axios.get(`request/enums/${_id}`, { noProgress: noProgress });
  },

  processRequest(_id, request, noProgress = true) {
    return Vue.axios.post(`request/process/${_id}`, request, {
      noProgress: noProgress,
    });
  },

  //communities
  getCommunities(_id, noProgress = true, isActive = false) {
    return Vue.axios.get(
      `community/list/${_id}/${isActive ? "active" : "all"}`,
      { noProgress: noProgress }
    );
  },

  addCommunity(_id, community, noProgress = true) {
    return Vue.axios.post(`community/${_id}`, community, {
      noProgress: noProgress,
    });
  },

  editCommunity(_id, community, noProgress = true) {
    return Vue.axios.put(`community/${_id}`, community, {
      noProgress: noProgress,
    });
  },

  deleteCommunity(_id, noProgress = true) {
    return Vue.axios.delete(`community/${_id}`, { noProgress: noProgress });
  },

  getCommunityEnums(_id, noProgress) {
    return Vue.axios.get(`community/enums/${_id}`, { noProgress: noProgress });
  },

  //email-config
  // eslint-disable-next-line
  getEmailConfig(_id, noProgress = true, isActive = false) {
    return Vue.axios.get(`email-config/list/${_id}`, {
      noProgress: noProgress,
    });
  },

  addEmailConfig(_id, emailConfig, noProgress = true) {
    return Vue.axios.post(`email-config/${_id}`, emailConfig, {
      noProgress: noProgress,
    });
  },

  editEmailConfig(_id, emailConfig, noProgress = true) {
    return Vue.axios.put(`email-config/${_id}`, emailConfig, {
      noProgress: noProgress,
    });
  },

  deleteEmailConfig(_id, noProgress = true) {
    return Vue.axios.delete(`email-config/${_id}`, { noProgress: noProgress });
  },

  getEmailConfigEnums(_id, noProgress) {
    return Vue.axios.get(`email-config/enums/${_id}`, {
      noProgress: noProgress,
    });
  },

  //domains
  verifyDomain(_id, obj, noProgress = true) {
    return Vue.axios.post(`store/${_id}/dns-verify`, obj, {
      noProgress: noProgress,
    });
  },

  //google places
  searchPlaces(_id, keyword, noProgress = true, country = "in") {
    return Vue.axios.get(`place/${_id}?keyword=${keyword}&country=${country}`, {
      noProgress: noProgress,
    });
  },

  verifyGSTN(_id, gstn, noProgress = true) {
    return Vue.axios.get(`gst/verify/${_id}/${gstn}`, {
      noProgress: noProgress,
    });
  },

  //payment links
  getPaymentLinks(_id, pageNumber, pageSize, keyword, noProgress = false) {
    let endpoint = `payment/link/list/${_id}/${pageNumber}/${pageSize}`;
    if (keyword) endpoint += `?keyword=${keyword}`;
    return Vue.axios.get(endpoint, { noProgress: noProgress });
  },

  createPaymentLink(_id, object, noProgress = false) {
    return Vue.axios.post(`payment/link/${_id}`, object, {
      noProgress: noProgress,
    });
  },

  editPaymentLink(_id, object, noProgress = false) {
    return Vue.axios.put(`payment/link/${_id}`, object, {
      noProgress: noProgress,
    });
  },

  cancelPaymentLink(_id, noProgress = false) {
    return Vue.axios.post(`payment/link/cancel/${_id}`, {
      noProgress: noProgress,
    });
  },

  notifyPaymentLink(_id, noProgress = false) {
    return Vue.axios.post(`payment/link/notify/${_id}`, {
      noProgress: noProgress,
    });
  },

  paymentLinkPaid(_id, noProgress = false) {
    return Vue.axios.post(`payment/link/paid/${_id}`, {
      noProgress: noProgress,
    });
  },

  deletePaymentLink(_id, noProgress = false) {
    return Vue.axios.post(`payment/link/delete/${_id}`, {
      noProgress: noProgress,
    });
  },

  //contracts
  getContracts(_id, noProgress = false) {
    return Vue.axios.get(`contract/list/${_id}`, { noProgress: noProgress });
  },

  getUserContracts(_id, userId, noProgress = false) {
    return Vue.axios.get(`contract/list/${_id}/${userId}`, {
      noProgress: noProgress,
    });
  },

  getUserContractsItems(_id, userId, noProgress = false) {
    return Vue.axios.get(`contract/list/items/${_id}/${userId}`, {
      noProgress: noProgress,
    });
  },

  createContract(_id, object, noProgress = false) {
    return Vue.axios.post(`contract/${_id}`, object, {
      noProgress: noProgress,
    });
  },

  editContract(_id, object, noProgress = false) {
    return Vue.axios.put(`contract/${_id}`, object, { noProgress: noProgress });
  },

  deleteContract(_id, noProgress = false) {
    return Vue.axios.delete(`contract/${_id}`, { noProgress: noProgress });
  },

  //webhooks
  getWebhookEnums(_id, noProgress = true) {
    return Vue.axios.get(`webhook/enums/${_id}`, { noProgress: noProgress });
  },

  getWebhooks(_id, noProgress = false) {
    return Vue.axios.get(`webhook/list/${_id}`, { noProgress: noProgress });
  },

  addWebhook(_id, object, noProgress = false) {
    return Vue.axios.post(`webhook/${_id}`, object, { noProgress: noProgress });
  },

  editWebhook(_id, object, noProgress = false) {
    return Vue.axios.put(`webhook/${_id}`, object, { noProgress: noProgress });
  },

  deleteWebhook(_id, noProgress = false) {
    return Vue.axios.delete(`webhook/${_id}`, { noProgress: noProgress });
  },

  triggerOrderWebhook(_id, _ids, action, noProgress = false) {
    return Vue.axios.post(`webhook/trigger/order/${action}/${_id}`, _ids, {
      noProgress: noProgress,
    });
  },

  //support tickets
  getTicketsEnums(noProgress = true) {
    return Vue.axios.get(`ticket/enums`, { noProgress: noProgress });
  },

  getTicket(_id, noProgress = false) {
    return Vue.axios.get(`ticket/${_id}`, { noProgress: noProgress });
  },

  getTickets(_id, noProgress = false) {
    return Vue.axios.get(`ticket/list/${_id}`, { noProgress: noProgress });
  },

  getGrowcifyTickets(noProgress = false) {
    return Vue.axios.get(`ticket/list/growcify`, { noProgress: noProgress });
  },

  createTicket(_id, object, noProgress = false) {
    return Vue.axios.post(`ticket/${_id}`, object, { noProgress: noProgress });
  },

  editTicket(_id, object, noProgress = false) {
    return Vue.axios.put(`ticket/${_id}`, object, { noProgress: noProgress });
  },

  deleteTicket(_id, noProgress = false) {
    return Vue.axios.delete(`ticket/${_id}`, { noProgress: noProgress });
  },

  getTicketComments(_id, noProgress = false) {
    return Vue.axios.get(`ticket/comment/list/${_id}`, {
      noProgress: noProgress,
    });
  },

  createTicketComment(_id, object, noProgress = false) {
    return Vue.axios.post(`ticket/comment/${_id}`, object, {
      noProgress: noProgress,
    });
  },

  editTicketComment(_id, object, noProgress = false) {
    return Vue.axios.put(`ticket/comment/${_id}`, object, {
      noProgress: noProgress,
    });
  },

  deleteTicketComment(_id, noProgress = false) {
    return Vue.axios.delete(`ticket/comment/${_id}`, {
      noProgress: noProgress,
    });
  },

  //invoices
  getAllInvoices(noProgress = false) {
    return Vue.axios.get(`invoice/list/all`, { noProgress: noProgress });
  },

  getInvoices(_id, noProgress = false) {
    return Vue.axios.get(`invoice/list/${_id}`, { noProgress: noProgress });
  },

  createInvoice(object, noProgress = false) {
    return Vue.axios.post(`invoice`, object, { noProgress: noProgress });
  },

  editInvoice(_id, object, noProgress = false) {
    return Vue.axios.put(`invoice/${_id}`, object, { noProgress: noProgress });
  },

  deleteInvoice(_id, noProgress = false) {
    return Vue.axios.delete(`invoice/${_id}`, { noProgress: noProgress });
  },

  downloadInvoices(_id, _ids, token) {
    let endpoint = `${
      process.env.NODE_ENV !== "production" ? config.apiDev : config.apiProd
    }invoice/${_id}/download/excel`;
    return window.open(
      endpoint +
        "?_ids=" +
        _ids +
        "&x-request-user-agent=application/admin-app&token=" +
        token,
      "_blank"
    );
  },

  //groups
  getGroups(_id, noProgress = false) {
    return Vue.axios.get(`group/list/${_id}/all`, { noProgress: noProgress });
  },

  createGroup(_id, object, noProgress = false) {
    return Vue.axios.post(`group/${_id}`, object, { noProgress: noProgress });
  },

  editGroup(_id, object, noProgress = false) {
    return Vue.axios.put(`group/${_id}`, object, { noProgress: noProgress });
  },

  deleteGroup(_id, noProgress = false) {
    return Vue.axios.delete(`group/${_id}`, { noProgress: noProgress });
  },

  getGroupsEnums(_id, noProgress = true) {
    return Vue.axios.get(`group/enums/${_id}`, { noProgress: noProgress });
  },

  //webstore
  getWebstore(_id, noProgress = false) {
    return Vue.axios.get(`webstore/${_id}`, { noProgress: noProgress });
  },

  createWebstore(_id, object, noProgress = false) {
    return Vue.axios.post(`webstore/${_id}`, object, {
      noProgress: noProgress,
    });
  },

  editWebstore(_id, object, noProgress = false) {
    return Vue.axios.put(`webstore/${_id}`, object, { noProgress: noProgress });
  },

  //whatsapp instance
  getWhatsAppInstance(_id, noProgress = false) {
    return Vue.axios.get(`wi/${_id}`, { noProgress: noProgress });
  },

  createWhatsAppInstance(_id, object, noProgress = false) {
    return Vue.axios.post(`wi/${_id}`, object, { noProgress: noProgress });
  },

  editWhatsAppInstance(_id, object, noProgress = false) {
    return Vue.axios.put(`wi/${_id}`, object, { noProgress: noProgress });
  },

  processWIPayment(_id, object, noProgress = false) {
    return Vue.axios.post(`wi/process-payment/${_id}`, object, {
      noProgress: noProgress,
    });
  },

  getWIQR(_id, noProgress = false) {
    return Vue.axios.get(`wi/qr/${_id}`, { noProgress: noProgress });
  },

  wiSendMessage(_id, object, noProgress = false) {
    return Vue.axios.post(`wi/send-message/${_id}`, object, {
      noProgress: noProgress,
    });
  },

  wiLogout(_id, object, noProgress = false) {
    return Vue.axios.post(`wi/logout/${_id}`, object, {
      noProgress: noProgress,
    });
  },

  //billing
  getBilling(_id, noProgress = false) {
    return Vue.axios.get(`billing/${_id}`, { noProgress: noProgress });
  },

  getAllBillings(noProgress = false) {
    return Vue.axios.get(`billing/list/all`, { noProgress: noProgress });
  },

  createBilling(_id, noProgress = false) {
    return Vue.axios.post(`billing/${_id}`, {}, { noProgress: noProgress });
  },

  editBilling(_id, object, noProgress = false) {
    return Vue.axios.put(`billing/${_id}`, object, { noProgress: noProgress });
  },

  deleteBilling(_id, noProgress = false) {
    return Vue.axios.delete(`billing/${_id}`, { noProgress: noProgress });
  },

  processBillingPayment(billingId, storeId, object, noProgress = false) {
    return Vue.axios.post(
      `billing/process-payment/${billingId}/${storeId}`,
      object,
      { noProgress: noProgress }
    );
  },

  //suppliers
  getSuppliers(_id, noProgress = true) {
    return Vue.axios.get(`supplier/list/${_id}`, { noProgress: noProgress });
  },

  addSupplier(_id, supplier, noProgress = true) {
    return Vue.axios.post(`supplier/${_id}`, supplier, {
      noProgress: noProgress,
    });
  },

  editSupplier(_id, supplier, noProgress = true) {
    return Vue.axios.put(`supplier/${_id}`, supplier, {
      noProgress: noProgress,
    });
  },

  deleteSupplier(_id, noProgress = true) {
    return Vue.axios.delete(`supplier/${_id}`, { noProgress: noProgress });
  },

  //stories
  getStories(_id, noProgress = true) {
    return Vue.axios.get(`story/list/${_id}/all`, { noProgress: noProgress });
  },

  createStory(_id, story, noProgress = true) {
    return Vue.axios.post(`story/${_id}`, story, {
      noProgress: noProgress,
    });
  },

  editStory(_id, story, noProgress = true) {
    return Vue.axios.put(`story/${_id}`, story, {
      noProgress: noProgress,
    });
  },

  deleteStory(_id, noProgress = true) {
    return Vue.axios.delete(`story/${_id}`, { noProgress: noProgress });
  },

  getStoryViews(storyId, noProgress = true) {
    return Vue.axios.get(`story/list/views/${storyId}`, {
      noProgress: noProgress,
    });
  },

  //screens
  getOnboardingScreens(_id, noProgress = true) {
    return Vue.axios.get(`onboarding-screen/list/${_id}/all`, {
      noProgress: noProgress,
    });
  },

  createOnboardingScreen(_id, screen, noProgress = true) {
    return Vue.axios.post(`onboarding-screen/${_id}`, screen, {
      noProgress: noProgress,
    });
  },

  editOnboardingScreen(_id, screen, noProgress = true) {
    return Vue.axios.put(`onboarding-screen/${_id}`, screen, {
      noProgress: noProgress,
    });
  },

  deleteOnboardingScreen(_id, noProgress = true) {
    return Vue.axios.delete(`onboarding-screen/${_id}`, {
      noProgress: noProgress,
    });
  },

  // hot collections
  getHotCollections(_id, noProgress = true) {
    return Vue.axios.get(`hot-collection/list/${_id}/all`, {
      noProgress: noProgress,
    });
  },

  createHotCollection(_id, hotCollection, noProgress = true) {
    return Vue.axios.post(`hot-collection/${_id}`, hotCollection, {
      noProgress: noProgress,
    });
  },

  editHotCollection(_id, hotCollection, noProgress = true) {
    return Vue.axios.put(`hot-collection/${_id}`, hotCollection, {
      noProgress: noProgress,
    });
  },

  deleteHotCollection(_id, noProgress = true) {
    return Vue.axios.delete(`hot-collection/${_id}`, {
      noProgress: noProgress,
    });
  },

  //purchase invoices
  getPurchaseInvoices(_id, noProgress = true) {
    return Vue.axios.get(`purchase/invoice/list/${_id}`, {
      noProgress: noProgress,
    });
  },

  getPurchaseInvoice(_id, noProgress = true) {
    return Vue.axios.get(`purchase/invoice/${_id}`, {
      noProgress: noProgress,
    });
  },

  createPurchaseInvoice(_id, object, noProgress = true) {
    return Vue.axios.post(`purchase/invoice/${_id}`, object, {
      noProgress: noProgress,
    });
  },

  editPurchaseInvoice(_id, object, noProgress = true) {
    return Vue.axios.put(`purchase/invoice/${_id}`, object, {
      noProgress: noProgress,
    });
  },

  deletePurchaseInvoice(_id, noProgress = true) {
    return Vue.axios.delete(`purchase/invoice/${_id}`, {
      noProgress: noProgress,
    });
  },

  getPurchaseInvoiceEnums(_id, noProgress = true) {
    return Vue.axios.get(`purchase/invoice/enums/${_id}`, {
      noProgress: noProgress,
    });
  },

  downloadPurchaseInvoice(_id, token) {
    let endpoint = `${
      process.env.NODE_ENV !== "production" ? config.apiDev : config.apiProd
    }purchase/invoice/download/${_id}`;
    return window.open(
      endpoint + "?x-request-user-agent=application/admin-app&token=" + token,
      "_blank"
    );
  },

  // purchase returns
  getPurchaseReturns(_id, noProgress = true) {
    return Vue.axios.get(`purchase/return/list/${_id}`, {
      noProgress: noProgress,
    });
  },

  getPurchaseReturn(_id, noProgress = true) {
    return Vue.axios.get(`purchase/return/${_id}`, {
      noProgress: noProgress,
    });
  },

  createPurchaseReturn(_id, object, noProgress = true) {
    return Vue.axios.post(`purchase/return/${_id}`, object, {
      noProgress: noProgress,
    });
  },

  editPurchaseReturn(_id, object, noProgress = true) {
    return Vue.axios.put(`purchase/return/${_id}`, object, {
      noProgress: noProgress,
    });
  },

  deletePurchaseReturn(_id, noProgress = true) {
    return Vue.axios.delete(`purchase/return/${_id}`, {
      noProgress: noProgress,
    });
  },

  getPurchaseReturnEnums(_id, noProgress = true) {
    return Vue.axios.get(`purchase/return/enums/${_id}`, {
      noProgress: noProgress,
    });
  },

  downloadPurchaseReturn(_id, token) {
    let endpoint = `${
      process.env.NODE_ENV !== "production" ? config.apiDev : config.apiProd
    }purchase/return/download/${_id}`;
    return window.open(
      endpoint + "?x-request-user-agent=application/admin-app&token=" + token,
      "_blank"
    );
  },

  // vendor locations
  getVendorLocations(_id, noProgress = true) {
    return Vue.axios.get(`vendor/location/list/${_id}`, {
      noProgress: noProgress,
    });
  },

  getVendorLocation(_id, noProgress = true) {
    return Vue.axios.get(`vendor/location/${_id}`, {
      noProgress: noProgress,
    });
  },

  createVendorLocation(_id, object, noProgress = true) {
    return Vue.axios.post(`vendor/location/${_id}`, object, {
      noProgress: noProgress,
    });
  },

  editVendorLocation(_id, object, noProgress = true) {
    return Vue.axios.put(`vendor/location/${_id}`, object, {
      noProgress: noProgress,
    });
  },

  deleteVendorLocation(_id, noProgress = true) {
    return Vue.axios.delete(`vendor/location/${_id}`, {
      noProgress: noProgress,
    });
  },

  // add-ons
  processGrowcifyAddon(_id, object, noProgress = true) {
    return Vue.axios.post(`store/purchase/addon/${_id}`, object, {
      noProgress: noProgress,
    });
  },

  modifyBillingDueDate(object, noProgress = true) {
    return Vue.axios.post(`billing/modify-due-date`, object, {
      noProgress: noProgress,
    });
  },

  //super-admins
  getSuperadminNumbers(type, noProgress = true) {
    return Vue.axios.get(`store/san/${type}`, { noProgress: noProgress });
  },

  getKYC(noProgress = true) {
    return Vue.axios.get(`store/sa/kyc`, { noProgress: noProgress });
  },

  updateKYC(_id, payload, noProgress = true) {
    return Vue.axios.put(`store/sa/kyc/${_id}`, payload, {
      noProgress: noProgress,
    });
  },

  massDeleteStores(payload, noProgress = true) {
    return Vue.axios.post(`store/power/mass-delete`, payload, {
      noProgress: noProgress,
    });
  },
};
