import moment from "moment";
import _ from "underscore";
import config from "../../config";
import Store from "../../services/store";
import FileUpload from "../../services/file-upload";
import { getS3URL } from "../../helpers/misc";

export default {
  name: "HotCollections",

  created() {
    this._id = this.$route.params._id;
    if (!this.doIHaveAccess("actions"))
      this.headers = this.headers.filter((x) => x.text !== "Actions");

    this.fetchHotCollections();
  },

  methods: {
    amIAllowed(module) {
      return Store.amIAllowed(this.$store, module);
    },

    doIHaveAccess(access) {
      return Store.doIHaveAccess(this.$store, access);
    },

    fetchHotCollections() {
      this.processing = this.loading = true;
      return Store.getHotCollections(this._id, false)
        .then((x) => {
          this.hotCollections = x.data;
          this.hotCollections.forEach(
            (x, index) => (x.index = index + 1 + ".")
          );
          this.processing = this.loading = false;
        })
        .catch((e) => {
          console.log(e);
          this.processing = this.loading = false;
        });
    },

    editItem(item) {
      this.editedIndex = this.hotCollections.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.editedItem.products.forEach((x, i) => (x.index = i));
      this.dialog = true;
    },

    deleteItem(item) {
      this.confirmDialog = true;
      this.beingDeleted = item;
    },

    closeConfirm() {
      this.confirmDialog = false;
      this.beingDeleted = {};
    },

    finalDelete() {
      this.processing = true;
      return Store.deleteHotCollection(this.beingDeleted._id, false)
        .then(() => {
          this.confirmDialog = this.processing = false;
          this.fetchHotCollections();
        })
        .catch((e) => {
          this.confirmDialog = this.processing = false;
          console.log(e);
        });
    },

    close() {
      this.dialog = this.processing = this.loading = false;
      this.editedItem = Object.assign({}, this.defaultItem);
    },

    formatDateTime(value) {
      return moment(value).format("lll");
    },

    changeStatus(item) {
      this.editedItem = Object.assign({}, item);
      this.saveHotCollection(false);
    },

    getSequenceItems() {
      const arr = [1];
      for (let i = 0; i < this.hotCollections.length; i++) arr.push(i + 2);
      return arr;
    },

    isVideo(item) {
      if (item) {
        const array = item.split(".");
        return array.includes("mp4");
      }
      return false;
    },

    saveHotCollection(close = true) {
      if (!this.editedItem.file) {
        return this.invalidImageError("Media Required");
      }
      if (this.$refs.form && !this.$refs.form.validate()) return;

      Store.snackbarStart();
      this.processing = this.editedItem.processing = true;

      let Request;
      let object = Object.assign({}, this.editedItem);
      if (object.products) {
        object.products = object.products.map((x) => x._id || x);
      }

      if (object._id) {
        Request = Store.editHotCollection(this.editedItem._id, object, false);
      } else {
        Request = Store.createHotCollection(this._id, object, false);
      }

      return Request.then(() => {
        this.processing = this.editedItem.processing = false;
        Store.snackbarSuccess();
        if (close) {
          this.close();
          this.fetchHotCollections();
        }
      }).catch((e) => {
        console.log(e);
        this.processing = this.editedItem.processing = false;
        Store.snackbarError();
      });
    },

    getRef() {
      return this.$refs.inputUpload;
    },

    pickFile() {
      this.getRef().click();
    },

    isFileImage(file) {
      if (file && file["type"].split("/")[0] === "image") {
        this.editedItem.type = file["type"].split("/")[0];
        return true;
      }
      return false;
    },

    getURL(data) {
      if (data && data.split(".")[1] === "png") {
        return getS3URL(this._id, data);
      }
      return data;
    },

    invalidImageError(msg = "Invalid file format") {
      this.showErr = msg;
      setTimeout(() => {
        this.showErr = false;
      }, 2000);
    },

    fileSelected() {
      const file = this.getRef().files[0];
      if (!this.isFileImage(file)) return this.invalidImageError();

      this.uploading = true;
      return FileUpload.upload(
        this._id,
        "hot-collections",
        file,
        this.editedItem.type
      )
        .then((x) => {
          this.editedItem.file = x.data.file;
          this.uploading = false;
          if (this.editedItem._id) this.saveHotCollection(false);
        })
        .catch((e) => {
          console.log(e);
          this.uploading = false;
        });
    },

    productSelected() {
      this.lastSearched = this.selectedProduct.name;
      if (
        this.editedItem.products.find((x) => x._id === this.selectedProduct._id)
      )
        return;

      this.selectedProduct.index = this.editedItem.products.length;
      this.editedItem.products.push(this.selectedProduct);
      this.selectedProduct = {};
      this.searchInput = "";
    },

    productText(item) {
      return item.name + " (" + item.size + item.unit + ")";
    },

    removeItem(item) {
      this.editedItem.products = this.editedItem.products.filter(
        (x) => x._id != item._id
      );
    },

    querySelections(keyword) {
      this.lastSearched = keyword;
      let thisRequest = ++this.lastRequest;
      this.outstandingRequests++;
      this.isSearching = true;

      let Request;
      const isCWMV = Store.isMultivendorUI(["categories"]);
      if (!isCWMV) {
        Request = Store.searchProducts(this._id, keyword, false);
      } else {
        Request = Store.searchMVProducts(this._id, keyword, false);
      }

      return Request.then((x) => {
        this.outstandingRequests--;
        if (thisRequest < this.lastResponse) return;
        this.lastResponse = thisRequest;

        this.products = x.data;
        this.isSearching = false;
      }).catch(() => {
        this.outstandingRequests--;
        this.isSearching = false;
      });
    },

    ofText(item) {
      return this.$options.filters.firstLetterUppercase(item);
    },
  },

  computed: {
    formTitle() {
      return this.editedIndex === -1
        ? "Create new hot collection"
        : "Edit collection";
    },

    isDT() {
      return this.$vuetify.theme.dark;
    },

    tc() {
      return this.isDT ? "text-white" : "text-black";
    },

    isVendor() {
      return this.$store.state.activeStore.isVendor;
    },

    canCreate() {
      return this.doIHaveAccess("create");
    },
  },

  watch: {
    hotCollectionUpdated(n) {
      if (n) this.fetchHotCollections();
      this.hotCollectionUpdated = false;
    },

    searchInput: _.debounce(function(newVal, oldVal) {
      const array = newVal ? newVal.split("(") : [];
      if (
        newVal &&
        newVal != oldVal &&
        newVal != this.lastSearched &&
        array.length === 1
      )
        this.querySelections(newVal);
    }, 500),
  },

  data() {
    return {
      _id: "",
      hotCollections: [],

      valid: false,
      processing: false,
      loading: false,
      uploading: false,
      showErr: false,
      dialog: false,
      hotCollectionUpdated: false,

      search: "",

      editedIndex: -1,
      editedItem: {},
      defaultItem: {
        file: "",
        isActive: true,
        sequenceNumber: 1,
        products: [],
      },

      confirmDialog: false,
      beingDeleted: {},
      rowItems: config.rowItems,

      categories: [],

      headers: [
        { text: "#", align: "start", value: "index" },
        { text: "Title", value: "title" },
        { text: "Status", value: "isActive" },
        { text: "Actions", align: "center", value: "actions", sortable: false },
      ],

      products: [],

      searchInput: "",
      lastSearched: "",
      lastRequest: 0,
      lastResponse: 0,
      isSearching: false,
      selectedProduct: {},
      outstandingRequests: 0,

      pheaders: [
        { text: "#", align: "start", value: "index", sortable: false },
        { text: "Name", value: "name" },
        { text: "Size", value: "size", sortable: false },
        { text: "Status", value: "isActive", sortable: false },
        { text: "Remove", value: "actions", sortable: false, align: "center" },
      ],

      validationRules: {
        basicRule: [(v) => !!v || "It can not be empty"],
      },
    };
  },
};
