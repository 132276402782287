import moment from "moment";

import Store from "../../services/store";
import Meeting from "../../services/meeting";

export default {
  name: "MeetingScheduler",

  props: ["meetingInfo", "meetingScheduled"],

  methods: {
    setTimer() {
      let timeString = this.meetingItem.startTime + ":00";
      let dateObj = new Date(this.meetingItem.startDate + " " + timeString);
      this.meetingItem.start = moment(dateObj).format();
      if (["15m", "30m", "45m"].includes(this.meetingItem.quickTime))
        this.meetingItem.end = moment(dateObj)
          .add(this.meetingItem.quickTime.replace("m", ""), "minutes")
          .format();
      else if (this.meetingItem.quickTime == "1h")
        this.meetingItem.end = moment(dateObj)
          .add(1, "hours")
          .format();

      this.meetingItem.endTime = moment(this.meetingItem.end).format("HH:mm");
    },

    getMeetingTiming() {
      return this.meetingItem.start
        ? moment(this.meetingItem.start).format("lll")
        : false;
    },

    close() {
      this.$store.state.meetingSchedulerModal = false;
    },

    scheduleMeeting() {
      if (this.$refs.form && !this.$refs.form.validate()) return;

      this.processing = true;
      Store.snackbarStart();

      const meetingObject = Object.assign({}, this.meetingItem);
      return Meeting.scheduleMeeting(meetingObject, false)
        .then((x) => {
          this.processing = false;
          if (x.data && x.data.ok) {
            this.$emit("update:meetingScheduled", true);
            Store.snackbarSuccess();
            this.close();
          } else {
            Store.snackbarError();
          }
        })
        .catch((e) => {
          console.log(e);
          this.processing = false;
          Store.snackbarError();
        });
    },

    fetchMeetings() {
      this.mfetching = true;
      let dateObj = new Date(this.meetingItem.startDate + " " + "00:00");
      const timings = {
        start: moment(dateObj).format(),
        end: moment(dateObj)
          .add("24", "hours")
          .format(),
      };
      return Meeting.getMeetingsByDate(timings, false)
        .then((x) => {
          this.meetings = x.data;
          this.allMeetings = x.data;
          this.mfetching = false;
        })
        .catch((e) => {
          console.log(e);
          this.mfetching = false;
        });
    },

    formatDate(date, format = "ll") {
      return moment(date).format(format);
    },

    mapAttendees(attendees) {
      return attendees.map((x) => x.email).join(", ");
    },

    getDuration(start, end) {
      return moment.duration(moment(end).diff(start)).asMinutes();
    },

    isHappeningNow(start, end) {
      return moment().isBetween(start, end);
    },

    inTime(start) {
      return moment(start).fromNow();
    },

    remove(item) {
      this.meetingItem.attendees.splice(
        this.meetingItem.attendees.indexOf(item),
        1
      );
      this.meetingItem.attendees = [...this.meetingItem.attendees];
    },
  },

  watch: {
    "$store.state.meetingSchedulerModal"(n) {
      if (n) {
        if (this.meetingInfo.email)
          this.meetingItem.attendees = [this.meetingInfo.email];
        if (this.meetingInfo.name)
          this.meetingItem.summary = this.meetingInfo.name + " < > Growcify";
        if (this.meetingInfo.leadId)
          this.meetingItem.lead = this.meetingInfo.leadId;

        this.fetchMeetings();
      } else {
        this.meetingItem = {
          quickTime: "30m",
          summary: "",
          via: "Google Meet",
          startDate: new Date().toISOString().substr(0, 10),
          startTime: "",
          start: "",
          end: "",
          attendees: [],
        };
      }
    },

    "meetingItem.quickTime"(n) {
      if (n && this.meetingItem.startTime) this.setTimer();
    },

    startTimeModal(n) {
      if (!n && this.meetingItem.quickTime && this.meetingItem.startTime)
        this.setTimer();
    },

    "meetingItem.startDate"(n) {
      if (n && this.meetingItem.quickTime && this.meetingItem.startTime)
        this.setTimer();

      if (n) this.fetchMeetings();
    },

    search(n) {
      if (!n) return (this.meetings = this.allMeetings);
      this.meetings = this.allMeetings.filter(
        (x) => x.summary.toLowerCase().indexOf(n.toLowerCase()) > -1
      );
    },
  },

  computed: {
    isDT() {
      return this.$vuetify.theme.dark;
    },
  },

  data() {
    return {
      processing: false,
      meetingItem: {
        quickTime: "30m",
        summary: "",
        via: "Google Meet",
        startDate: new Date().toISOString().substr(0, 10),
        startTime: "",
        start: "",
        end: "",
      },
      minDate: new Date().toISOString(),
      startTimeModal: false,

      search: "",
      mfetching: false,
      meetings: [],
      allMeetings: [],

      validationRules: {
        basicRule: [(v) => !!v || ""],
      },
    };
  },
};
