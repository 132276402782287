import Store from "../../../services/store";
import FileUpload from "../../../services/file-upload";
import moment from "moment";
import router from "../../../router";
import { getS3URL } from "../../../helpers/misc";
import config from "../../../config";

export default {
  name: "Releases",

  created() {
    this._id = this.$route.params._id;
    if (
      (!Store.doIHaveAdminAccess(this.$store) &&
        !Store.doIHaveTestingAccess(this.$store)) ||
      this.$store.state.activeStore.isVendor
    )
      return router.push("/business/" + this._id);

    this.fetchReleases();
    this.fetchReleaseEnums();
  },

  methods: {
    fetchReleases() {
      this.processing = this.loading = true;
      this._id = this.$route.params._id;
      return Store.getReleases(this._id, false)
        .then((x) => {
          this.releases = x.data;
          this.releases.forEach((x, index) => {
            x.index = index + 1 + ".";
            x.time = moment(x.createdAt).format("lll");
          });
          this.processing = this.loading = false;
        })
        .catch((e) => {
          console.log(e);
          this.processing = this.loading = false;
        });
    },

    fetchReleaseEnums() {
      return Store.getReleaseEnums(this._id)
        .then((x) => (this.enums = x.data))
        .catch((e) => console.log(e));
    },

    editItem(item) {
      this.editedIndex = this.releases.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
      this.editedItem.order = this.editedIndex >= 0 ? this.editedIndex + 1 : 1;
    },

    deleteItem(item) {
      this.confirmDialog = true;
      this.beingDeleted = item;
    },

    closeConfirm() {
      this.confirmDialog = false;
      this.beingDeleted = {};
    },

    finalDelete() {
      this.processing = true;
      return Store.deleteRelease(this.beingDeleted._id, false)
        .then(() => {
          this.confirmDialog = this.processing = false;
          this.fetchReleases();
        })
        .catch((e) => {
          this.confirmDialog = this.processing = false;
          console.log(e);
        });
    },

    close() {
      this.dialog = this.processing = this.loading = false;
      setTimeout(
        () => (this.editedItem = Object.assign({}, this.defaultItem)),
        100
      );
    },

    save(closeModal = true, fetchReleases = true) {
      if (!this.$refs.form.validate()) {
        /*if (!this.editedItem.file) {
          this.showErr = 'File is required';
          setTimeout(() => { this.showErr = false }, 2500);
        }*/

        //this.$refs.form.validate();
        return;
      }

      Store.snackbarStart();
      this.processing = true;
      let request;

      const object = Object.assign({}, this.editedItem);
      if (this.editedItem._id)
        request = Store.editRelease(this.editedItem._id, object, false);
      else request = Store.createRelease(this._id, object, false);

      return request
        .then(() => {
          if (closeModal) this.close();
          if (fetchReleases) this.fetchReleases();
          this.processing = false;
          Store.snackbarSuccess();
        })
        .catch((e) => {
          console.log(e);
          this.processing = false;
          this.loading = false;
          Store.snackbarError();
        });
    },

    getRef() {
      return this.$refs.inputUpload;
    },

    pickFile() {
      this.getRef().click();
    },

    isValidFile(file) {
      if (
        file &&
        (file.name.search("apk") > -1 || file.name.search("ipa") > -1)
      ) {
        this.editedItem.platform =
          file.name.search("apk") > -1 ? "Android" : "iOS";
        return true;
      }
      return false;
    },

    getURL(data) {
      if (
        data &&
        (data.split(".")[1] === "apk" || data.split(".")[1] === "ipa")
      ) {
        return getS3URL(this._id, data);
      }
      return data;
    },

    invalidFileError() {
      this.showErr = "Invalid file format";
      setTimeout(() => {
        this.showErr = false;
      }, 2000);
    },

    fileSelected() {
      const file = this.getRef().files[0];
      if (!this.isValidFile(file)) return this.invalidFileError();

      const platform = this.editedItem.platform.toLowerCase();
      this.uploading = true;
      return FileUpload.upload(this._id, "apps", file, platform)
        .then((x) => {
          this.editedItem.file = x.data.file;
          this.uploading = false;
          if (this.editedItem._id) this.save(false, false);
        })
        .catch((e) => {
          console.log(e);
          this.uploading = false;
          if (e.status === 400)
            this.showErr("Request timeout: please try again");
          setTimeout(() => {
            this.showErr = false;
          }, 2000);
        });
    },

    download(item) {
      window.open(this.getURL(item.file), "_blank");
    },

    copyURL(path) {
      const url = this.getURL(path);
      navigator.clipboard.writeText(url);
    },
  },

  computed: {
    formTitle() {
      return this.editedIndex === -1
        ? "Create new app release"
        : "Edit app release";
    },

    isSuperadmin() {
      return this.$store.state.userInfo.roles.includes("superadmin");
    },

    isGrowcifyEmployee() {
      return Store.amIGrowcifyEmployee(this.$store);
    },

    basePath() {
      return config.growcifyProjectsS3BucketURL;
    },

    isDT() {
      return this.$vuetify.theme.dark;
    },
  },

  data() {
    return {
      _id: "",

      enums: {},
      releases: [],

      valid: false,
      processing: false,
      loading: false,
      uploading: false,
      showErr: false,
      dialog: false,

      search: "",

      editedIndex: -1,
      editedItem: {},
      defaultItem: {
        isActive: true,
        file: undefined,
        title: "1st Release",
        platform: "Android",
        version: "1.0",
        new: "All new APK",
        status: "Waiting for Review",
      },

      confirmDialog: false,
      beingDeleted: {},

      headers: [
        { text: "#", align: "start", value: "index" },
        { text: "Title", value: "title" },
        { text: "Platform", value: "platform" },
        { text: "Version", value: "version" },
        { text: "Status", value: "status" },
        { text: "Date & Time", value: "time" },
        {
          text: this.$store.state.userInfo.roles.includes("superadmin")
            ? "Actions"
            : "Download",
          align: "center",
          value: "actions",
          sortable: false,
        },
      ],

      validationRules: {
        basicRule: [(v) => !!v || "It can not be empty"],
      },
    };
  },
};
