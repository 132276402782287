import _ from "underscore";

import Store from "../../services/store";
import i18n from "../../services/i18n";

export default {
  name: "Countries",

  created() {
    if (!this.canProceed) return Store.redirectToHome();

    this.fetchCountries();
    this.fetchLanguages();
    this.fetchCommonEnums();

    this.fetchStates();
    this.fetchCities();
  },

  methods: {
    fetchLanguages() {
      return i18n
        .getLanguages(false)
        .then((res) => (this.languages = res.data))
        .catch((e) => console.log(e));
    },

    fetchCountries() {
      this.loading = true;
      return Store.getCountries(false)
        .then((res) => {
          this.countries = res.data.map((x, index) => {
            x.index = index + 1 + ".";
            return x;
          });
          this.loading = false;
        })
        .catch((e) => {
          console.log(e);
          this.loading = false;
        });
    },

    fetchStates() {
      this.sloading = true;
      return Store.getStates(false)
        .then((res) => {
          this.states = res.data.map((x, index) => {
            x.index = index + 1 + ".";
            return x;
          });
          this.allStates = JSON.parse(JSON.stringify(this.states));
          this.sloading = false;
        })
        .catch((e) => {
          console.log(e);
          this.sloading = false;
        });
    },

    fetchCities() {
      this.cloading = true;
      return Store.getCities(false)
        .then((res) => {
          this.cities = res.data.map((x, index) => {
            x.index = index + 1 + ".";
            return x;
          });
          this.allCities = JSON.parse(JSON.stringify(this.cities));
          this.cloading = false;
        })
        .catch((e) => {
          console.log(e);
          this.cloading = false;
        });
    },

    fetchCommonEnums() {
      const ce = this.$store.state.commonEnums;
      if (ce) return (this.cenums = ce);

      return Store.getCommonEnums()
        .then((x) => {
          this.cenums = x.data;
          this.$store.state.commonEnums = x.data;
        })
        .catch((e) => console.log(e));
    },

    editItem(item) {
      this.editedIndex = this.countries.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.confirmDialog = true;
      this.beingDeleted = item;
    },

    closeConfirm() {
      this.confirmDialog = false;
      this.beingDeleted = {};
    },

    finalDelete() {
      if (this.beingDeleted.isState) this.finalStateDelete();
      else if (this.beingDeleted.isCity) this.finalCityDelete();
      else {
        this.processing = true;
        Store.deleteCountry(this.beingDeleted._id, false)
          .then(() => {
            this.confirmDialog = this.processing = false;
            this.fetchCountries();
          })
          .catch((e) => {
            this.confirmDialog = this.processing = false;
            console.log(e);
          });
      }

      this.beingDeleted.isState = this.beingDeleted.isCity = false;
    },

    close() {
      this.dialog = this.processing = this.loading = false;
      this.editedItem = Object.assign({}, this.defaultItem);
    },

    changeStatus(item) {
      this.editedItem = item;
      this.save(false, true);
    },

    toggleTranslationModal(field) {
      this.translationModal = !this.translationModal;
      this.translationField = field;
    },

    saveTranslation() {
      this.translationModal = !this.translationModal;
      if (this.editedItem._id) this.save(false, false);
    },

    save(closeModal = true, farceSave = false) {
      if (!farceSave && this.$refs.form && !this.$refs.form.validate()) return;
      this.processing = this.editedItem.processing = true;

      Store.snackbarStart();
      const object = Object.assign({}, this.editedItem);

      let request;
      if (this.editedItem._id)
        request = Store.editCountry(this.editedItem._id, object, false);
      else request = Store.addCountry(object, false);

      return request
        .then(() => {
          if (closeModal) this.close();
          this.processing = this.editedItem.processing = false;
          Store.snackbarSuccess();
          this.fetchCountries();
        })
        .catch(() => {
          Store.snackbarError();
          this.$store.state.snackbar.text = "Country Already Exists";
          this.processing = false;
          this.loading = false;
        });
    },

    editStateItem(item) {
      this.editedStateIndex = this.states.indexOf(item);
      this.editedStateItem = Object.assign({}, item);
      this.stateDialog = true;
    },

    deleteStateItem(item) {
      item.isState = true;
      this.beingDeleted = item;
      this.confirmDialog = true;
    },

    finalStateDelete() {
      this.processing = true;
      return Store.deleteState(this.beingDeleted._id, false)
        .then(() => {
          this.confirmDialog = this.processing = false;
          this.fetchStates();
        })
        .catch((e) => {
          this.confirmDialog = this.processing = false;
          console.log(e);
        });
    },

    closeState() {
      this.stateDialog = this.processing = this.loading = false;
      this.editedStateItem = Object.assign({}, this.defaultStateItem);
    },

    changeStateStatus(item) {
      this.editedStateItem = item;
      this.saveState(false, true);
    },

    saveState(closeModal = true, farceSave = false) {
      if (!farceSave && this.$refs.form2 && !this.$refs.form2.validate())
        return;
      this.processing = this.editedStateItem.processing = true;

      Store.snackbarStart();
      const object = Object.assign({}, this.editedStateItem);

      let request;
      if (this.editedStateItem._id)
        request = Store.editState(this.editedStateItem._id, object, false);
      else
        request = Store.addState(this.editedStateItem.country, object, false);

      return request
        .then(() => {
          if (closeModal) this.closeState();
          this.processing = this.editedStateItem.processing = false;
          Store.snackbarSuccess();
          this.fetchStates();
        })
        .catch(() => {
          Store.snackbarError();
          this.$store.state.snackbar.text = "State Already Exists";
          this.processing = false;
          this.loading = false;
        });
    },

    editCityItem(item) {
      this.editedCityIndex = this.states.indexOf(item);
      this.editedCityItem = Object.assign({}, item);
      this.cityDialog = true;
    },

    deleteCityItem(item) {
      item.isCity = true;
      this.beingDeleted = item;
      this.confirmDialog = true;
    },

    finalCityDelete() {
      this.processing = true;
      return Store.deleteCity(this.beingDeleted._id, false)
        .then(() => {
          this.confirmDialog = this.processing = false;
          this.fetchCities();
        })
        .catch((e) => {
          this.confirmDialog = this.processing = false;
          console.log(e);
        });
    },

    closeCity() {
      this.cityDialog = this.processing = this.loading = false;
      this.editedCityItem = Object.assign({}, this.defaultCityItem);
    },

    changeCityStatus(item) {
      this.editedCityItem = item;
      this.saveCity(false, true);
    },

    saveCity(closeModal = true, farceSave = false) {
      if (!farceSave && this.$refs.form3 && !this.$refs.form3.validate())
        return;
      this.processing = this.editedCityItem.processing = true;

      Store.snackbarStart();
      const object = Object.assign({}, this.editedCityItem);
      if (object.pincodes && object.pincodes.length)
        object.pincodes = object.pincodes.map((x) => x.trim());

      let request;
      if (this.editedCityItem._id)
        request = Store.editCity(this.editedCityItem._id, object, false);
      else request = Store.addCity(this.editedCityItem.state, object, false);

      return request
        .then(() => {
          if (closeModal) this.closeCity();
          this.processing = this.editedCityItem.processing = false;
          Store.snackbarSuccess();
          this.fetchCities();
        })
        .catch(() => {
          Store.snackbarError();
          this.$store.state.snackbar.text = "City Already Exists";
          this.processing = false;
          this.loading = false;
        });
    },

    handlePincodes() {
      const allPincodes = this.editedCityItem.pincodes;
      const newArray = allPincodes
        .join(", ")
        .split(",")
        .filter((x) => x);
      this.editedCityItem.pincodes = newArray && newArray[0] ? newArray : [];
    },

    removePincode(item) {
      this.editedCityItem.pincodes.splice(
        this.editedCityItem.pincodes.indexOf(item),
        1
      );
      this.editedCityItem.pincodes = [...this.editedCityItem.pincodes];
    },
  },

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Add new country" : "Edit country";
    },

    amISuperadmin() {
      return Store.amISuperadmin(this.$store);
    },

    canProceed() {
      return (
        (Store.amISuperadmin(this.$store) ||
          Store.amIGrowcifyEmployee(this.$store)) &&
        this.$store.state.isGD
      );
    },

    isDT() {
      return this.$vuetify.theme.dark;
    },
  },

  watch: {
    "editedItem.callingCode": _.debounce(function(nv) {
      if (nv) this.editedItem.callingCode = nv.replace("+", "").trim();
    }, 500),

    sssearch(n) {
      if (!n) return (this.states = this.allStates);
      this.states = this.allStates.filter((x) => x.country == n);
    },

    ccsearch(n) {
      if (!n) return (this.cities = this.allCities);
      this.cities = this.allCities.filter((x) => x.state == n);
    },
  },

  data: () => {
    return {
      countries: [],
      languages: [],
      search: "",

      loading: true,
      valid: false,
      dialog: false,
      processing: false,

      error: "",

      cenums: {},

      translationModal: false,
      translationField: "",

      editedIndex: -1,
      editedItem: {},
      defaultItem: {
        isActive: true,
      },

      confirmDialog: false,
      beingDeleted: {
        isState: false,
        isCity: false,
      },

      stateDialog: false,
      cityDialog: false,
      sloading: false,
      cloading: false,
      states: [],
      cities: [],

      ssearch: "",
      sssearch: "",
      csearch: "",
      ccsearch: "",

      editedStateItem: { isActive: true },
      editedStateIndex: -1,
      defaultStateItem: { isActive: true },

      editedCityIndex: -1,
      editedCityItem: { isActive: true, pincodes: [] },
      defaultCityItem: { isActive: true },

      headers: [
        { text: "#", align: "start", value: "index" },
        { text: "Name", align: "start", value: "name" },
        { text: "Calling Code", value: "callingCode" },
        { text: "Currency", value: "currencyName" },
        { text: "Status", value: "status" },
        { text: "Actions", align: "center", value: "actions", sortable: false },
      ],

      sheaders: [
        { text: "#", align: "start", value: "index" },
        { text: "Name", value: "name" },
        { text: "Code", value: "code" },
        { text: "GST Code", value: "gstCode" },
        { text: "Status", value: "status" },
        { text: "Actions", align: "center", value: "actions", sortable: false },
      ],

      cheaders: [
        { text: "#", align: "start", value: "index" },
        { text: "Name", align: "start", value: "name" },
        { text: "Status", value: "status" },
        { text: "Actions", align: "center", value: "actions", sortable: false },
      ],

      validationRules: {
        basicRule: [(v) => !!v || "It is required"],
        statusRule: [(v) => v || v === false || "It is required"],
        callingCode: [(v) => v >= 0 || "Enter digits only"],
      },
    };
  },
};
